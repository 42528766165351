
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
      password: "",
      confirm: "",
    });

    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);

    onMounted(async () => {

      const mCodeArk = window.localStorage.getItem('codeArk');
      if (mCodeArk) {
        state.codeArk = mCodeArk;
        fCodeArk();
      }

      if (state.codeArk == "") {
        state.codeArkError = "Le code Ark est obligatoire"
        return false;
      }
      fCodeArk();
    });

      const fCodeArk = async () => {

      const rCodeArk = await getAxios("/getCodeArk/" + state.codeArk);
      if (rCodeArk.results == 'KO') {
        state.codeArkError = "Le code Ark est inconnu, veuillez le vérifier svp."
        return false;
      }
      window.localStorage.setItem('codeArk', state.codeArk);
      state.codeArkSociete = rCodeArk.results;
      state.loaderEnabled = false;
    }

    //Create form validation object
    const login = Yup.object().shape({
      password: Yup.string()
      .required('Le mot de passe est obligatoire') 
      .min(8, 'Votre mot de passe doit contenir au minimum 8 caractères'),

      confirm: Yup.string()
      .required('La confirmation est obligatoire') 
      .oneOf([Yup.ref('password'), null], 'Le mot de passe doit être le même')
    });


    //Form submit function
    const onSubmitLogin = async () => {
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      const data = {
        username: "",
        password: state.password,
        token: router.currentRoute.value.params.token
      }

      let response = await mAxiosApi.post("/setNewPassword", data) as any;
      response = response.data
      if (response.results == "ok") {
        data.username = response.mail
        setTimeout(() => {
          // Send login request
          store
            .dispatch(Actions.LOGIN, data)
            .then(() => {
              Swal.fire({
                title: store.getters.currentUser.societeCodeArk ? "Connexion établie : " + store.getters.currentUser.societeCodeArk : "Connexion établie !", 
                text:
                  "Bienvenue " +
                  store.getters.currentUser.us_prenom +
                  " " +
                  store.getters.currentUser.us_nom +
                  " sur espace Audioptic Deploy !",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Accèder à Audioptic Deploy",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                
                if (router.currentRoute.value.params.redirect) {
                  const stringRec = (router.currentRoute.value.params.redirect) as string;
                  const token = router.currentRoute.value.params.token
                  if(token) {
                    router.push({ name: "loader", params: {redirect: stringRec, token: token} });
                  }else{
                    router.push({ name: stringRec });
                  }
                } else {

                  store.dispatch(Actions.SET_CURRENT_BORD, {
                    bordSeq: 0,
                    bordType : ''
                  });

                  router.push({ name: "board" });
                }

              });
            })
            .catch(() => {
              Swal.fire({
                text: "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Retourner sur la page de connexion",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        }, 500);
      }else{
        Swal.fire({
          text: response.results.error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Retourner sur la page de connexion",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        }).then(function (){
          router.push("/sign-in")
        });
      }
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
